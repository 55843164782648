import { breakpoints, colors, fonts } from '../styles';
import React, { useState } from 'react';
import reactStringReplace from 'react-string-replace';
import styled from 'styled-components';

interface QuestionTextProps {
    questionPhrase: string;
    questionDomain: string;
    questionDomainExplanation: string;
    shoudShowTooltip?: boolean;
    shouldShowQuestionPhrase?: boolean;
}

interface QuestionDomainProps {
    questionDomain: string;
    mouseAction: (arg0: boolean) => void;
    shoudShowTooltip?: boolean;
}

const ExplanationPopup = styled.div`
    color: ${colors.white};
    background-color: ${colors.black};
    box-sizing: border-box;
    padding: 15px;
    border-radius: 3px;
    position: absolute;
    width: 100%;
    top: 30px;
    transform: translate(0, -55%);
    font-size: 16px;
    ${breakpoints.onlyMobile} {
        font-size: 12px;
    }
`;

const QuestionPhrase = styled.div``;

// const QuestionDomainExplanation = styled.div`
//     color: ${colors.white};
//     background-color: ${colors.black};
//     box-sizing: border-box;
//     padding: 15px;
//     border-radius: 3px;
//     position: absolute;
//     ${fonts.indication};
//     width: 100%;
//     top: 0;
//     transform: translate(0, -55%);
// `;

interface QuestionDomainCSSProps {
    shouldUnderline: boolean;
}

const QuestionDomain = styled.span<QuestionDomainCSSProps>`
    text-decoration: ${(props) =>
        props.shouldUnderline ? 'underline' : 'inherit'};
    font-weight: bold;
    color: ${colors.skillColor};
`;

const QuestionDomainElement: React.FC<QuestionDomainProps> = ({
    questionDomain,
    mouseAction,
    shoudShowTooltip = true,
}) => {
    return (
        <QuestionDomain
            shouldUnderline={shoudShowTooltip}
            onMouseOver={() => mouseAction(true)}
            onMouseOut={() => mouseAction(false)}
            dangerouslySetInnerHTML={{
                __html: questionDomain,
            }}
        />
    );
};

const QuestionText: React.FC<QuestionTextProps> = ({
    questionPhrase,
    questionDomain,
    questionDomainExplanation,
    shoudShowTooltip = true,
    shouldShowQuestionPhrase = true,
}) => {
    const [isShown, setIsShown] = useState(false);
    return (
        <React.Fragment>
            {shouldShowQuestionPhrase ? (
                <QuestionPhrase>
                    {reactStringReplace(
                        questionPhrase,
                        '<DOMAIN>',
                        (match, i) => {
                            return (
                                <QuestionDomainElement
                                    key={i}
                                    questionDomain={questionDomain}
                                    shoudShowTooltip={shoudShowTooltip}
                                    mouseAction={setIsShown}
                                />
                            );
                        },
                    )}
                    <span>?</span>
                </QuestionPhrase>
            ) : (
                <QuestionPhrase>
                    {
                        <QuestionDomainElement
                            key={questionPhrase}
                            questionDomain={questionDomain}
                            shoudShowTooltip={shoudShowTooltip}
                            mouseAction={setIsShown}
                        />
                    }
                </QuestionPhrase>
            )}
            {isShown && shoudShowTooltip && (
                <ExplanationPopup
                    onMouseEnter={() => setIsShown(true)}
                    onMouseOut={() => setIsShown(false)}
                    dangerouslySetInnerHTML={{
                        __html: questionDomainExplanation,
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default QuestionText;
