import { Fade } from './Fade';
import React from 'react';
import { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

interface AnimateProps {
    changeOn: any;
    children: React.ReactNode;
}

export const AnimateItem: React.FC<AnimateProps> = ({ children, changeOn }) => {
    const duration = 200;
    const [visible, setVisible] = useState(false);
    const [content, setContent] = useState(children);
    const [prevChangeOn, setPrevChangeOn] = useState();

    useEffect(() => {
        if (changeOn !== prevChangeOn) {
            setPrevChangeOn(changeOn);
        } else {
            setContent(children);
        }
    }, [children, changeOn]);

    useEffect(() => {
        if (
            typeof changeOn === 'undefined' &&
            typeof prevChangeOn === 'undefined'
        )
            return setVisible(true);
        /// Le titre va changer

        // On animate out
        setVisible(false);

        // On change le contenu quand le out est passé
        setTimeout(() => {
            setContent(children);
        }, duration);

        // On anime in
        setTimeout(() => {
            setVisible(true);
        }, duration);
    }, [changeOn]);
    return (
        <Transition in={visible} timeout={duration}>
            {(state) => (
                // state change: exited -> entering -> entered -> exiting -> exited
                <Fade duration={duration / 1000} state={state}>
                    {content}
                </Fade>
            )}
        </Transition>
    );
};
