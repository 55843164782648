import { colors, breakpoints } from '../styles';
import DomainBackground from './DomainBackground';
import QuestionProgressDisplay from './QuestionProgressDisplay';
import React from 'react';
import styled from 'styled-components';

interface SurveyIllustrationProps {
    image: string;
    progress: number;
    total: number;
    color: string;
}

const SurveyIllustationProgressWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SizedDomainBackground = styled(DomainBackground)`
    position: absolute;
    left: 20px;
    height: 30%;
    width: 50%;
    top: 0;
    ${breakpoints.md} {
        height: 460px;
        width: 460px;
        top: 20px;
    }
    ${breakpoints.sm} {
        height: 460px;
        width: 460px;
        top: 20px;
    }
    ${breakpoints.lg} {
        height: 460px;
        width: 460px;
        top: 20px;
    }
    ${breakpoints.largeDesktop} {
        height: 460px;
        width: 460px;
        top: 20px;
    }
    ${breakpoints.largerDesktop} {
        height: 460px;
        width: 460px;
        top: 20px;
    }
    border-radius: 1000px;
`;

const PositionQuestionProgressDisplay = styled(QuestionProgressDisplay)`
    position: relative;
    z-index: 1;
`;

const SurveyIllustationProgress: React.FC<SurveyIllustrationProps> = ({
    image,
    progress,
    total,
    color,
}) => (
    <SurveyIllustationProgressWrapper>
        <SizedDomainBackground color={color} />
        <PositionQuestionProgressDisplay
            amountOfQuestions={total}
            progress={progress}
            doneProgressColor={colors.textColor1}
            toDoColor={colors.libertyGray10}
            centerImage={image}
        />
    </SurveyIllustationProgressWrapper>
);

export default SurveyIllustationProgress;
