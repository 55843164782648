import breakpoints from './breakpoints';
import { css } from 'styled-components';

const titleFont = css`
    font-family: 'Open Sans', sans-serif;
`;

const bigTitle = css`
    font-size: 2.1em;
    font-family: 'Open Sans', sans-serif;
`;

const title = css`
    font-size: 52px;
    ${breakpoints.onlyMobile} {
        font-size: 1.2em;
    }
`;

const subtitle = css`
    font-size: 36px;
    ${breakpoints.onlyMobile} {
        font-size: 1.5em;
    }
`;

const content = css`
    font-size: 18px;
    ${breakpoints.onlyMobile} {
        font-size: 1em;
    }
`;

const textContent = css`
    font-size: 24px;
    ${breakpoints.onlyMobile} {
        font-size: 1em;
    }
`;

const content_target = css`
    font-size: 1em;
    ${breakpoints.md} {
        font-size: 0.7em;
    }
    ${breakpoints.lg} {
        font-size: 0.6em;
    }
`;

const indication = css`
    font-size: 24px;
    ${breakpoints.onlyMobile} {
        font-size: 16px;
    }
`;

const button = css`
    font-size: 23px;
`;

export default {
    content,
    title,
    subtitle,
    indication,
    titleFont,
    bigTitle,
    button,
    content_target,
    textContent,
};
