import configureStore from './configureStore';
import App from './containers/App';
import './i18n';
import 'core-js';
import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

const store = configureStore();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <Suspense fallback={<h1>Please wait ...</h1>}>
            <App />
        </Suspense>
    </Provider>,
);
