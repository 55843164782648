import { fonts, colors } from '../styles';
import CircleIcon from './CircleIcon';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface ProgressControlsProps {
    count?: number;
    total?: number;
    className?: string;
    backPath?: string;
    forwardPath?: string;
    onClickForward?: () => void;
    onClickBackward?: () => void;
    emailAuth?: any;
}

const ProgressControlWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProgressIndicator = styled.div`
    ${fonts.content};
    color: ${colors.white};
`;

const getNavigationElement = (
    navigationIndicator: string | (() => void) | undefined,
    type: CircleType,
    emailAuth: any,
): React.ReactElement => {
    if (typeof navigationIndicator === 'function') {
        return (
            <CircleIcon
                onClick={() => navigationIndicator()}
                type={type}
                active={true}
                backgroundColor={colors.white}
            />
        );
    }
    if (typeof navigationIndicator === 'string') {
        return (
            <NavLink to={navigationIndicator} state={{ emailAuth: emailAuth }}>
                <CircleIcon
                    type={type}
                    active={true}
                    backgroundColor={colors.white}
                />
            </NavLink>
        );
    }

    return <CircleIcon type={type} active={false} borderColor={colors.white} />;
};

const ProgressControls: React.FC<ProgressControlsProps> = ({
    count,
    total,
    className,
    backPath,
    forwardPath,
    onClickForward,
    onClickBackward,
    emailAuth,
}) => {
    return (
        <ProgressControlWrapper className={className}>
            {getNavigationElement(
                backPath || onClickBackward,
                'previous',
                emailAuth,
            )}
            {typeof count !== 'undefined' && total && (
                <ProgressIndicator>
                    {count.toString()}/{total.toString()}
                </ProgressIndicator>
            )}
            {getNavigationElement(
                forwardPath || onClickForward,
                'next',
                emailAuth,
            )}
        </ProgressControlWrapper>
    );
};

export default ProgressControls;
