import { containerStyles, colors, breakpoints } from '../styles';
import React from 'react';
import styled from 'styled-components';

interface InformationLayoutCSSProps {
    leftSize: number;
    leftSideBackgroundColor?: string;
    rightSideBackgroundColor?: string;
}

interface InformationLayoutContentCSSProps {
    leftSize: number;
    leftSideBackgroundColor?: string;
    rightSideBackgroundColor?: string;
    withButton?: boolean;
    canOverflow?: boolean;
}

interface InformationLayoutProps {
    leftSize?: number;
    leftSideBackgroundColor?: string;
    rightSideBackgroundColor?: string;
    withButton?: boolean;
    canOverflow?: boolean;
    children?: React.ReactNode;
    logoImage?: string;
}

const getCalc = (leftSize: number) =>
    `calc((100% + ${(containerStyles.maxWidth * leftSize) / 100}px ) / 2)`;

const InformationLayoutWrapper = styled.div<InformationLayoutCSSProps>`
    ${containerStyles.fullPage};
    background: ${(props) =>
        props.leftSize === 100
            ? props.leftSideBackgroundColor
            : `linear-gradient(120deg, #13152a, #4e53a4)`};

    background: ${(props) =>
        props.leftSize === 100
            ? props.leftSideBackgroundColor
            : `linear-gradient(
        90deg, ${props.leftSideBackgroundColor} ${getCalc(props.leftSize)},
        ${props.rightSideBackgroundColor} ${getCalc(props.leftSize)}
    )`};
`;

const InformationLayoutContent = styled.div<InformationLayoutContentCSSProps>`
    ${containerStyles.fullPageContent}
    display: flex;
    flex-direction: ${(props) => (props.withButton ? 'column' : 'row')};
    > * {
        &:nth-child(1) {
            /* ICI */
            height: ${(props) =>
                props.withButton && !props.canOverflow ? '100%' : ''};
            width: ${(props) =>
                props.withButton ? '100%' : `${props.leftSize}%`};
        }

        &:nth-child(2) {
            width: ${(props) =>
                props.withButton ? '100%' : `${100 - props.leftSize}%`};
        }
    }
    /* grid-template-columns: ${(props) =>
        props.withButton ? 'none' : `${props.leftSize}% auto`}; */
    ${breakpoints.onlyMobile} {
        width: 100%;
    }
`;

const LogoImage = styled.img`
    max-height: 100%;
    width: 14.3%;
    margin-right: 10%;

    ${breakpoints.lg} {
        max-height: 100%;
        width: 14.3%;
        margin-right: 10%;
    }

    ${breakpoints.onlyMobile} {
        max-width: 25%;
        max-height: 100%;
        width: 100%;
    }

    ${breakpoints.md} {
        max-height: 100%;
        width: 14.3%;
        margin-right: 10%;
    }
`;

const LogoImageContainer = styled.div`
     {
        display: flex;
        justify-content: flex-end;
    }
`;

const InformationLayout: React.FC<InformationLayoutProps> = ({
    leftSize = 55,
    leftSideBackgroundColor = colors.backgroundColor1,
    rightSideBackgroundColor = colors.backgroundColor2,
    children,
    withButton = false,
    canOverflow = false,
    logoImage,
}) => {
    return (
        <React.Fragment>
            <InformationLayoutWrapper
                leftSize={leftSize}
                leftSideBackgroundColor={leftSideBackgroundColor}
                rightSideBackgroundColor={rightSideBackgroundColor}
            >
                <LogoImageContainer>
                    <LogoImage src={logoImage} />
                </LogoImageContainer>
                <InformationLayoutContent
                    leftSize={leftSize}
                    withButton={withButton}
                    leftSideBackgroundColor={leftSideBackgroundColor}
                    rightSideBackgroundColor={rightSideBackgroundColor}
                    canOverflow={canOverflow}
                >
                    {children}
                </InformationLayoutContent>
            </InformationLayoutWrapper>
        </React.Fragment>
    );
};

export default InformationLayout;
