import defaultCenterImage from '../assets/svg/center_image_example.svg';
import React from 'react';
import styled from 'styled-components';

interface QuestionProgressDisplayProps {
    /**
     * Total Amount of questions
     */
    amountOfQuestions?: number;

    /**
     * Question progress (out of total amount of questions)
     */
    progress?: number;

    /**
     * Total size of the element (in pixels)
     */
    size?: number;

    /**
     * Padding for the sides (in pixels)
     */
    sideMargin?: number;

    /**
     * Width of the circle tick (in pixels)
     */
    tickWidth?: number;

    /**
     * Circle radius (in pixels)
     */
    radius?: number;

    /**
     * Tick Spacing in % of PI
     * @default 0.02
     */

    tickSpacing?: number;

    /**
     * Center Image url
     */
    centerImage?: string;

    /**
     * Color of the tick when the question is done or in progress
     */
    doneProgressColor?: string;

    /**
     * Color of the tick when the question is to do
     */
    toDoColor?: string;

    className?: string;
}

const QuestionProgressDisplayWrapper = styled.svg``;

const QuestionProgressMiddleImage = styled.image``;

const QuestionProgressProgressWrapper = styled.g``;

const QuestionProgressTick = styled.path`
    fill: none;
`;

const getXCoordinateForPoint = (
    circleRadius: number,
    tickSpacing: number,
    step: number,
    totalAmountOfArcs: number,
): number =>
    circleRadius *
    Math.cos(
        (step * 2 * Math.PI) / totalAmountOfArcs +
            tickSpacing * Math.PI -
            Math.PI / 2,
    );

const getYCoordinateForPoint = (
    circleRadius: number,
    tickSpacing: number,
    step: number,
    totalAmountOfArcs: number,
): number =>
    circleRadius *
    Math.sin(
        (step * 2 * Math.PI) / totalAmountOfArcs +
            tickSpacing * Math.PI -
            Math.PI / 2,
    );

const getPathForCircleArc = (
    radius: number,
    step: number,
    tickSpacing: number,
    totalAmountOfArcs: number,
): string => {
    const x0 = getXCoordinateForPoint(
        radius,
        tickSpacing,
        step,
        totalAmountOfArcs,
    );
    const y0 = getYCoordinateForPoint(
        radius,
        tickSpacing,
        step,
        totalAmountOfArcs,
    );

    /**
     * No tickSpacing for destination (would cancel tickSpacing)
     */
    const x1 = getXCoordinateForPoint(radius, 0, step + 1, totalAmountOfArcs);
    const y1 = getYCoordinateForPoint(radius, 0, step + 1, totalAmountOfArcs);

    const xAxisRotation = 0;
    const largeArcFlag = 0;
    const sweepFlag = 1;

    /**
     * M: Move
     * A : Eliptical arc
     * See: https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands
     * */
    return `M ${x0} ${y0} A ${radius} ${radius} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${x1} ${y1}`;
};

const QuestionProgressDisplay: React.FC<QuestionProgressDisplayProps> = ({
    amountOfQuestions = 2,
    progress = 1,
    size = 500,
    tickWidth = 5,
    sideMargin = 10,
    tickSpacing = 0.01,
    centerImage = defaultCenterImage,
    doneProgressColor = '#CDCDCD',
    toDoColor = 'black',
    className,
}): React.ReactElement => {
    const radius = size / 2 - sideMargin;

    /**
     * For the image to be in the middle, it has to be smaller than the whole svg
     */
    const imageSize = size / 1.5 - sideMargin;

    return (
        <QuestionProgressDisplayWrapper
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            className={className}
        >
            <QuestionProgressMiddleImage
                href={centerImage}
                width={imageSize}
                height={imageSize}
                x={'50%'}
                y={'50%'}
                transform={`translate(${-(imageSize / 2)}, ${-(
                    imageSize / 2
                )})`}
            />

            <QuestionProgressProgressWrapper
                transform={`translate(${size / 2}, ${size / 2})`}
                strokeWidth={tickWidth}
                width={size}
                height={size}
            >
                {/** Display as many ticks as there are questions */}
                {[...Array(amountOfQuestions)].map(
                    (e, i): React.ReactElement => (
                        <QuestionProgressTick
                            key={`question-indicator-${i}`}
                            stroke={
                                i < progress ? doneProgressColor : toDoColor
                            }
                            d={getPathForCircleArc(
                                radius,
                                i,
                                tickSpacing,
                                amountOfQuestions,
                            )}
                        />
                    ),
                )}
            </QuestionProgressProgressWrapper>
        </QuestionProgressDisplayWrapper>
    );
};

export default QuestionProgressDisplay;
