import arrow from '../assets/svg/dropdownarrow.svg';
import colors from '../styles/colors';
import fonts from '../styles/fonts';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

interface CollapsibleProps {
    title: string;
    content: string | React.ReactElement;
}

const contentStyle = css`
    color: ${colors.libertyBlue};
    margin-top: 32px;
    ${fonts.content};
    line-height: 1.75;

    & a {
        color: ${colors.libertyDarkTeal};
    }
`;

const Content = styled.div`
    ${contentStyle}
`;

const ClickableContent = styled.div`
    ${contentStyle}
    cursor: pointer;
    text-decoration: underline;

    &::before {
        content: url('${arrow}');
        margin-right: 5px;
        display: inline-block;
        transform: rotate(-90deg);
    }

    &.open::before {
        content: url('${arrow}');
        margin-right: 5px;
        display: inline-block;
        transform: rotate(0deg);
    }
`;

const Collapsible: React.FC<CollapsibleProps> = ({
    title,
    content,
}): React.ReactElement => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    return (
        <>
            <ClickableContent
                onClick={toggle}
                dangerouslySetInnerHTML={{
                    __html: title as string,
                }}
                className={open ? 'open' : 'close'}
            />

            {open &&
                ((typeof content === 'string' && (
                    <Content
                        dangerouslySetInnerHTML={{
                            __html: content as string,
                        }}
                    />
                )) || <Content>{content}</Content>)}
        </>
    );
};

export default Collapsible;
