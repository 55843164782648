import { fonts, colors, breakpoints } from '../styles';
import React from 'react';
import styled from 'styled-components';

interface ExplanationProps {
    image: string;
    title: string;
    description: string;
    key: string;
    className?: string;
}

const ExplanationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.black};
    justify-content: space-between;
`;

const Illustration = styled.img`
    height: 100px;
    width: auto;
`;

const Title = styled.div`
    font-size: 1.2em;
    ${breakpoints.lg} {
        font-size: 32px;
    }
    ${breakpoints.largerDesktop} {
        font-size: 32px;
    }
    ${breakpoints.largeDesktop} {
        font-size: 32px;
    }
    ${breakpoints.sm} {
        font-size: 32px;
    }
    ${breakpoints.md} {
        font-size: 32px;
    }
    font-weight: bold;
    text-align: center;
    line-height: 1.3;
    width: 100%;
`;

const Description = styled.p`
    font-size: 1em;
    ${breakpoints.lg} {
        font-size: 18px;
    }
    text-align: center;
    width: 100%;
`;

const Explanation: React.FC<ExplanationProps> = ({
    image,
    title,
    description,
    key,
    className,
}) => (
    <ExplanationWrapper className={className} key={key}>
        <Illustration src={image} />
        <Title>{title}</Title>
        <Description>{description}</Description>
    </ExplanationWrapper>
);

export default Explanation;
