import { containerStyles, colors, breakpoints } from '../styles';
import React from 'react';
import styled from 'styled-components';

const getCalc = () => `50%`;

interface SurveyPageLayoutWrapperCSSProps {
    leftSideBackgroundColor: string;
    rightSideBackgroundColor: string;
    leftSize: number;
}

const SurveyPageLayoutWrapper = styled.div<SurveyPageLayoutWrapperCSSProps>`
    ${containerStyles.fullPage};
    position: relative;
    overflow-y: auto;
    background: ${(props) => props.leftSideBackgroundColor};

    ${breakpoints.md} {
        background: ${(props) =>
            props.leftSize === 100
                ? props.leftSideBackgroundColor
                : `linear-gradient(
            90deg, ${props.leftSideBackgroundColor} ${getCalc()},
            ${props.rightSideBackgroundColor} ${getCalc()}
        )`};
    }
    ${breakpoints.largeDesktop} {
        background: ${(props) =>
            props.leftSize === 100
                ? props.leftSideBackgroundColor
                : `linear-gradient(
            90deg, ${props.leftSideBackgroundColor} ${getCalc()},
            ${props.rightSideBackgroundColor} ${getCalc()}
        )`};
    }
    ${breakpoints.lg} {
        background: ${(props) =>
            props.leftSize === 100
                ? props.leftSideBackgroundColor
                : `linear-gradient(
            90deg, ${props.leftSideBackgroundColor} ${getCalc()},
            ${props.rightSideBackgroundColor} ${getCalc()}
        )`};
    }
    ${breakpoints.sm} {
        background: ${(props) =>
            props.leftSize === 100
                ? props.leftSideBackgroundColor
                : `linear-gradient(
            90deg, ${props.leftSideBackgroundColor} ${getCalc()},
            ${props.rightSideBackgroundColor} ${getCalc()}
        )`};
    }
    ${breakpoints.largerDesktop} {
        background: ${(props) =>
            props.leftSize === 100
                ? props.leftSideBackgroundColor
                : `linear-gradient(
            90deg, ${props.leftSideBackgroundColor} ${getCalc()},
            ${props.rightSideBackgroundColor} ${getCalc()}
        )`};
    }
`;
const SurveyPageLayoutContent = styled.div`
    ${containerStyles.fullPageContent}
    ${breakpoints.md} {
        display: flex;
        > * {
            &:nth-child(1) {
                width: 50%;
                padding-right: 60px;
            }
            &:nth-child(2) {
                width: 50%;
            }
        }
    }
    ${breakpoints.sm} {
        display: flex;
        > * {
            &:nth-child(1) {
                width: 50%;
                padding-right: 60px;
            }
            &:nth-child(2) {
                width: 50%;
            }
        }
    }
    ${breakpoints.lg} {
        display: flex;
        > * {
            &:nth-child(1) {
                width: 50%;
                padding-right: 60px;
            }
            &:nth-child(2) {
                width: 50%;
            }
        }
    }
    ${breakpoints.largerDesktop} {
        display: flex;
        > * {
            &:nth-child(1) {
                width: 50%;
                padding-right: 60px;
            }
            &:nth-child(2) {
                width: 50%;
            }
        }
    }
    ${breakpoints.largeDesktop} {
        display: flex;
        > * {
            &:nth-child(1) {
                width: 50%;
                padding-right: 60px;
            }
            &:nth-child(2) {
                width: 50%;
            }
        }
    }
    ${breakpoints.onlyMobile} {
        width: 100%;
        margin-top: 12px;
    }
`;

const LogoImage = styled.img`
    max-height: 100%;
    width: 14.3%;
    margin-right: 10%;

    ${breakpoints.lg} {
        max-height: 100%;
        width: 14.3%;
        margin-right: 10%;
    }

    ${breakpoints.md} {
        max-height: 100%;
        width: 14.3%;
        margin-right: 10%;
    }

    ${breakpoints.onlyMobile} {
        max-width: 25%;
        max-height: 100%;
        width: 100%;
    }
`;

const LogoImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SurveyPageLayout: React.FC<{
    children?: React.ReactNode;
    logoImage?: string;
}> = ({ children, logoImage }) => (
    <SurveyPageLayoutWrapper
        leftSize={5}
        leftSideBackgroundColor={colors.backgroundColor1}
        rightSideBackgroundColor={colors.backgroundColor2}
    >
        <LogoImageContainer>
            <LogoImage src={logoImage} />
        </LogoImageContainer>
        <SurveyPageLayoutContent>{children}</SurveyPageLayoutContent>
    </SurveyPageLayoutWrapper>
);

export default SurveyPageLayout;
