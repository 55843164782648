import {
    FEEDBACK_ANSWER_ERROR,
    FEEDBACK_ANSWER_LOADING,
    FEEDBACK_ANSWER_SUCCESS,
    FEEDBACK_FETCH_ERROR,
    FEEDBACK_FETCH_LOADING,
    FEEDBACK_FETCH_SUCCESS,
    ReduxAction,
} from '../actions/feedbackTypes';
import { Feedback } from '../model/models';

const feedbackState = (
    state: Feedback = {
        // options: [],
        questions: [],
        answers: [],
        // type: [],
    },
    action: ReduxAction,
): Feedback => {
    switch (action.type) {
        case FEEDBACK_FETCH_SUCCESS:
        case FEEDBACK_FETCH_ERROR:
        case FEEDBACK_FETCH_LOADING:
        case FEEDBACK_ANSWER_ERROR:
        case FEEDBACK_ANSWER_LOADING:
        case FEEDBACK_ANSWER_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default feedbackState;
