import { Domain } from '../model/models';
import { Action } from 'redux';

export const SURVEY_FETCH_SUCCESS = 'SURVEY_FETCH_SUCCESS';
export const SURVEY_FETCH_ERROR = 'SURVEY_FETCH_ERROR';
export const SURVEY_FETCH_LOADING = 'SURVEY_FETCH_LOADING';
export const SURVEY_ANSWER_SUCCESS = 'SURVEY_ANSWER_SUCCESS';
export const SURVEY_ANSWER_LOADING = 'SURVEY_ANSWER_LOADING';
export const SURVEY_ANSWER_ERROR = 'SURVEY_ANSWER_ERROR';
export const SURVEY_DOMAIN_CHANGE = 'SURVEY_DOMAIN_CHANGE';
export const SURVEY_SUBDOMAIN_CHANGE = 'SURVEY_SUBDOMAIN_CHANGE';
export const SURVEY_FIRST_TIME_LOADING = 'SURVEY_FIRST_TIME_LOADING';
export const APPLICATION_SETTING_FETCH_SUCCESS =
    'APPLICATION_SETTING_FETCH_SUCCESS';
export const APPLICATION_SETTING_FETCH_ERROR =
    'APPLICATION_SETTING_FETCH_ERROR';
export interface ReduxAction extends Action {
    payload?: object;
    error?: boolean;
    meta?: object;
}

export interface SurveyFetchSuccess extends ReduxAction {
    type: typeof SURVEY_FETCH_SUCCESS;
    payload: {
        domains: Domain[];
        domainCursor: number;
    };
}

export interface SurveyFetchError extends ReduxAction {
    type: typeof SURVEY_FETCH_ERROR;
    error: boolean;
    payload: {
        firstTimeLoading: boolean;
        error: Error;
        surveyClosed?: boolean;
    };
}

export interface SurveyFetchLoading extends ReduxAction {
    type: typeof SURVEY_FETCH_LOADING;
    payload: {
        loading: boolean;
    };
}

export interface SurveyAnswerSuccess extends ReduxAction {
    type: typeof SURVEY_ANSWER_SUCCESS;
    payload: Record<string, never>;
}

export interface SurveyAnswerError extends ReduxAction {
    type: typeof SURVEY_ANSWER_ERROR;
    error: boolean;
    payload: {
        error: Error;
    };
}

export interface SurveyAnswerLoading extends ReduxAction {
    type: typeof SURVEY_ANSWER_LOADING;
    payload: {
        answerLoading: boolean;
    };
}

export interface SurveyFirstTimeLoading extends ReduxAction {
    type: typeof SURVEY_FIRST_TIME_LOADING;
    payload: {
        firstTimeLoading: boolean;
    };
}

export interface AppliationSettingFetchSuccess extends ReduxAction {
    type: typeof APPLICATION_SETTING_FETCH_SUCCESS;
    payload: {
        staticDomainAverageColor?: string;
        feedbackFeatureEnabled?: boolean;
        domainDefaultProficiency?: number;
    };
}

export interface AppliationSettingFetchError extends ReduxAction {
    type: typeof APPLICATION_SETTING_FETCH_ERROR;
    error: boolean;
    payload: {
        error: Error;
    };
}
