import { Action } from 'redux';

export const FEEDBACK_FETCH_SUCCESS = 'FEEDBACK_FETCH_SUCCESS';
export const FEEDBACK_FETCH_ERROR = 'FEEDBACK_FETCH_ERROR';
export const FEEDBACK_FETCH_LOADING = 'FEEDBACK_FETCH_LOADING';
export const FEEDBACK_ANSWER_SUCCESS = 'FEEDBACK_ANSWER_SUCCESS';
export const FEEDBACK_ANSWER_LOADING = 'FEEDBACK_ANSWER_LOADING';
export const FEEDBACK_ANSWER_ERROR = 'FEEDBACK_ANSWER_ERROR';

export interface ReduxAction extends Action {
    payload?: object;
    error?: boolean;
    meta?: object;
}

export interface FeedbackFetchSuccess extends ReduxAction {
    type: typeof FEEDBACK_FETCH_SUCCESS;
    payload: any;
}

export interface FeedbackFetchError extends ReduxAction {
    type: typeof FEEDBACK_FETCH_ERROR;
    error: boolean;
    payload: any;
}

export interface FeedbackFetchLoading extends ReduxAction {
    type: typeof FEEDBACK_FETCH_LOADING;
    payload: {
        loading: boolean;
    };
}

export interface FeedbackAnswerSuccess extends ReduxAction {
    type: typeof FEEDBACK_ANSWER_SUCCESS;
    payload: any;
}

export interface FeedbackAnswerError extends ReduxAction {
    type: typeof FEEDBACK_ANSWER_ERROR;
    error: boolean;
    payload: {
        error: Error;
    };
}

export interface FeedbackAnswerLoading extends ReduxAction {
    type: typeof FEEDBACK_ANSWER_LOADING;
    payload: {
        answerLoading: boolean;
    };
}
