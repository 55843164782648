import Text from '../assets/page-text/FunctionalApplicationDisclaimer.json';
import InformationPageTemplate from '../components/InformationPageTemplate';
import React from 'react';

interface FunctionalApplicationsIntroductionProps {
    close: () => void;
}

const FunctionApplicationsIntroduction: React.FC<
    FunctionalApplicationsIntroductionProps
> = ({ close }): React.ReactElement => (
    <InformationPageTemplate
        title={Text.title}
        tagline={Text.tagline}
        content={Text.content}
        nextText={Text.nextText}
        middleImage={Text.middleImage}
        onButtonClick={() => close()}
        controls={false}
    />
);

export default FunctionApplicationsIntroduction;
