import { answerSubdomain } from '../actions/surveyActions';
import domainMapping from '../assets/page-text/DomainAndImageMapping.json';
import AnswerButton from '../components/AnswerButton';
import Answers from '../components/Answers';
import FunctionApplicationsIntroduction from '../components/FunctionalApplicationsIntroduction';
import SurveyIllustationProgress from '../components/SurveyIllustrationProgress';
import SurveyPageTemplate from '../components/SurveyPageTemplate';
import { validateId } from '../model/joi.models';
import { Domain, AnswerLevel, Subdomain, Id } from '../model/models';
import { State } from '../redux-state';
import { breakpoints, colors, fonts } from '../styles';
import levelToColor from '../styles/levelMappingToColor';
import useLoadingAndError from '../utils/useLoadingAndError';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Continue = styled.div`
    ${fonts.content};
    color: ${colors.libertyBlue};
    display: flex;
    align-items: center;
    ${breakpoints.onlyMobile} {
        margin-left: 0;
    }
    margin-left: 20px;
`;

const SizedAnswerButton = styled(AnswerButton)`
    ${breakpoints.onlyMobile} {
        margin: auto;
    }
    width: 250px;
    height: 50px;
`;

const CongratulationText = styled.div`
    ${fonts.content};
    color: ${colors.libertyBlue};
    margin-bottom: 50px;
`;

const getIsDoneContent = (
    congratulationText: string,
    nextText: string,
    domainName: string,
    enterShortcut: string,
    survey_id: any,
    uid: any,
    levels_state: any,
    emailAuth: any,
) => {
    return (
        <React.Fragment>
            <CongratulationText
                dangerouslySetInnerHTML={{
                    __html: congratulationText.replace('<DOMAIN>', domainName),
                }}
            />
            <SizedAnswerButton
                backgroundType="content"
                tooltipTextColor={colors.blue}
                color={colors.blue}
                shortcut={enterShortcut}
                to={`/${survey_id}/${uid}/survey-welcome`}
                levels_state={levels_state}
                emailAuth={emailAuth}
            >
                <Continue>{nextText}</Continue>
            </SizedAnswerButton>
        </React.Fragment>
    );
};

const getDomainFromUrl = (
    domainId: string,
    domains: Domain[],
): Domain | undefined => {
    if (domainId) {
        let id: Id;
        /**
         * Validate that the domain is indeed a domainID
         */
        try {
            id = validateId(domainId);
        } catch (err) {
            return;
        }

        /**
         * Find the domain in our domains list
         */
        const potentialDomain = domains.find(
            (domain: Domain) => domain.id === id,
        );

        /**
         * If we found it return it
         */
        return potentialDomain;
    }
};

const getCurrentLevelAverage = (domain: Domain) => {
    const a = Math.round(
        domain.subdomains.reduce(
            (acc, subdomain: Subdomain) => acc + (subdomain.level || 0),
            0,
        ) /
            domain.subdomains.filter(
                (subdomain: Subdomain) => subdomain.level != null,
            ).length,
    );

    if (!a) {
        return 0;
    }
    return a;
};

const didUserAnsweredNextQuestion = (
    subdomains: Subdomain[],
    currentSubdomainIndex: number,
) => {
    return (
        subdomains[currentSubdomainIndex] &&
        subdomains[currentSubdomainIndex].level &&
        subdomains[currentSubdomainIndex].level !== null
    );
};

const SurveyPage: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch();
    const [isDone, setIsDone] = useState(false);
    const [currentDomain, setCurrentDomain] = useState({
        subdomains: [],
    } as any as Domain);
    const [currentSubdomain, setCurrentSubdomain] = useState(
        {} as any as Subdomain,
    );

    const [isDomainCorrect, setIsDomainCorrect] = useState(true);
    const [currentSubdomainIndex, setCurrentSubdomainIndex] = useState(0);

    const [
        shouldSeeFunctionalApplicationDisclaimer,
        setShouldSeeFunctionalApplicationDisclaimer,
    ] = useState(false);

    const params = useParams();
    const domainId = params.domainId || '-1';

    const answering = useSelector(
        (state: State): boolean => state.surveyState.answerLoading,
    );

    /**
     * Bind last timestamp to re-render on answer if required (edge case)
     */

    const lastTimeStamp: number = useSelector(
        (state: State): number => state.surveyState.userInfos.lastAnsweredTime,
    );

    /**
     * Bind all domains to this container
     */
    const domains: Domain[] = useSelector(
        (state: State): Domain[] => state.surveyState.domains,
    );

    const averageLevelColor: any = useSelector(
        (state: State): string =>
            state.surveyState.staticDomainAverageColor || '',
    );

    /**
     * Bind app loading and error state
     */
    const { loading, error } = useLoadingAndError();

    /**
     * Effect functions: These will cause side effects
     */

    /**
     * @Effect
     * Change internal current subdomain index
     */

    const effectFunctionSetSubdomainIndexFromCursorChange = (): void => {
        setCurrentSubdomainIndex(currentDomain.subdomainCursor);
    };

    /**
     * @Effect
     * Get current domain and set it
     */
    const effectFunctionSetDomain = (): void => {
        const domain = getDomainFromUrl(domainId, domains);
        if (domain) setCurrentDomain(domain);
        else if (!loading && !error) setIsDomainCorrect(false);
    };

    /**
     * @Effect
     * Get current subdomain and set it
     */

    const effectFunctionSetSubdomain = () => {
        if (currentDomain && currentDomain.subdomains)
            setCurrentSubdomain(
                currentDomain.subdomains[currentSubdomainIndex],
            );
    };

    /**
     * @Effect
     * Check if the domain is done
     */
    const effectFunctionSetIsDone = () => {
        if (
            currentDomain.completed &&
            currentSubdomainIndex >= currentDomain.subdomains.length
        ) {
            setIsDone(true);
        } else {
            setIsDone(false);
        }
    };

    /**
     * @Effect
     * Check if the domain is done
     */
    const effectFunctionSetHasSeenFunctionalApplicationDisclaimer = () => {
        if (currentDomain.mandatory) {
            setShouldSeeFunctionalApplicationDisclaimer(true);
        }
    };

    /**
     * On answer click, request
     * @param subdomain
     * @param answerLevel
     */
    const onAnswer = (
        subdomain: Subdomain,
        answerLevel: AnswerLevel,
        survey_id: any,
        uid: any,
    ) => {
        if (subdomain && !answering) {
            dispatch(answerSubdomain(subdomain, answerLevel, survey_id, uid));
        }
    };

    /**
     * Place the cursor on the next subdomain
     */
    const nextSubdomain = () => {
        if (
            didUserAnsweredNextQuestion(
                currentDomain.subdomains,
                currentSubdomainIndex,
            )
        )
            setCurrentSubdomainIndex(currentSubdomainIndex + 1);
    };

    /**
     * Place the cursor on the previous subdomain
     */
    const previousSubdomain = () => {
        if (currentSubdomainIndex > 0) {
            setCurrentSubdomainIndex(currentSubdomainIndex - 1);
        }
    };

    const getNextFunction = (
        currentIndex: number,
        totalQuestion: number,
        isDone: boolean,
        nextFunction: () => void,
    ) => {
        return (currentIndex === totalQuestion && !isDone) ||
            isDone ||
            !didUserAnsweredNextQuestion(
                currentDomain.subdomains,
                currentSubdomainIndex,
            )
            ? undefined
            : nextFunction;
    };

    useEffect(effectFunctionSetSubdomainIndexFromCursorChange, [
        currentDomain,
        lastTimeStamp,
    ]);
    useEffect(effectFunctionSetDomain, [domains, domainId]);
    useEffect(effectFunctionSetSubdomain, [
        currentDomain,
        currentSubdomainIndex,
    ]);
    useEffect(effectFunctionSetIsDone, [currentDomain, currentSubdomainIndex]);
    useEffect(effectFunctionSetHasSeenFunctionalApplicationDisclaimer, [
        currentDomain.id,
    ]);

    const { t } = useTranslation(['Survey']);
    const { survey_id, uid } = useParams();

    // if (loading || error) return <div>Loading or error</div>;
    if (!isDomainCorrect) return <Navigate to="/tutorial" />;
    if (shouldSeeFunctionalApplicationDisclaimer) {
        return (
            <FunctionApplicationsIntroduction
                close={() => setShouldSeeFunctionalApplicationDisclaimer(false)}
            />
        );
    }

    let emailAuth;
    let levels_state;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;
    console.log(stateVar);
    if (stateVar) {
        if ('levels_state' in stateVar) {
            levels_state = stateVar.levels_state;
        }
        if ('emailAuth' in stateVar) {
            emailAuth = stateVar.emailAuth;
        }
    }

    console.log(emailAuth);

    useEffect(() => {
        if (!stateVar) {
            navigate(`/${survey_id}/${uid}`, { replace: true });
        }
    }, []);
    return !stateVar ? (
        <></>
    ) : (
        <SurveyPageTemplate
            title={currentDomain.title}
            listDescription={true}
            doneText={isDone ? t('doneText') || '' : undefined}
            helpText={t('helpText') || ''}
            logoImage="/talentbuilderlogo.png"
            questionPhrase={
                currentDomain.mandatory
                    ? t('mandatoryQuestionPhrase')
                    : t('questionPhrase')
            }
            questionDomain={currentSubdomain && currentSubdomain.title}
            questionDomainExplanation={
                currentSubdomain && currentSubdomain.explanation
            }
            targetSample={currentSubdomain && currentSubdomain.explanation}
            progress={
                currentSubdomainIndex + 1 > currentDomain.subdomains.length
                    ? currentDomain.subdomains.length
                    : currentSubdomainIndex + 1
            }
            progressTotal={currentDomain.subdomains.length}
            nextClick={getNextFunction(
                currentSubdomainIndex,
                currentDomain.subdomains.length - 1,
                isDone,
                () => nextSubdomain(),
            )}
            backClick={
                currentSubdomainIndex > 0
                    ? () => previousSubdomain()
                    : undefined
            }
            illustrationSideContent={
                <SurveyIllustationProgress
                    image={domainMapping[currentDomain.idx]}
                    progress={currentSubdomainIndex}
                    total={currentDomain.subdomains.length}
                    color={
                        averageLevelColor
                            ? averageLevelColor
                            : levels_state.levels_data[
                                  getCurrentLevelAverage(currentDomain)
                              ].color
                    }
                />
            }
            shouldShowTooltip={false}
        >
            {isDone ? (
                getIsDoneContent(
                    t('congratulationText'),
                    t('nextText'),
                    currentDomain.title,
                    t('enter'),
                    survey_id,
                    uid,
                    levels_state,
                    emailAuth,
                )
            ) : (
                <Answers
                    loading={loading}
                    levels_state={levels_state}
                    survey_id={survey_id}
                    previousAnswer={currentSubdomain && currentSubdomain.level}
                    onAnswer={(answerLevel: AnswerLevel) =>
                        onAnswer(currentSubdomain, answerLevel, survey_id, uid)
                    }
                />
            )}
        </SurveyPageTemplate>
    );
};

export default SurveyPage;
