import { submitMoreInfoAnswers } from '../actions/surveyActions';
import InformationPageTemplate from '../components/InformationPageTemplate';
import {
    MoreInfoFreetext,
    MoreInfoDropdown,
} from '../components/MoreInfoQuestion';
import { MoreInfoAnswerObject } from '../model/models';
import { State } from '../redux-state';
import { colors } from '../styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { authenticatedGetJson } from '../utils/requests';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Subtitle = styled.div`
    color: ${colors.libertyBlue};
    margin-bottom: 50px;
`;

const Questions = styled.div`
    > *:not(.domain) {
        margin-bottom: 50px;
    }
    .domain {
        margin-bottom: 25px;
    }
`;

const ErrorMessage = styled.p<{ showError: boolean }>`
    visibility: ${(props) => (props.showError ? 'visible' : 'hidden')};
    color: red;
    font-size: 13px;
    margin: 0;
    word-wrap: break-word;
    margin-left: 58px;
`;

const getCurrentRole = (roleId: any, roles: any[]) => {
    if (roleId) {
        const foundObject = roles.find(
            (obj) => obj.id === parseInt(roleId, 10),
        );
        return foundObject !== undefined ? foundObject.role : null;
    }
    return null;
};

const getMoreInfoPageContent = (
    answersObject: MoreInfoAnswerObject,
    setAnswersObject: (arg0: any) => void,
) => {
    const { t } = useTranslation(['Informations']);

    const [client_name, setClientName] = useState<any>('');
    useEffect(() => {
        async function fetchConfig() {
            const response = await fetch(`${backendUrl}/get_client_info`);
            const data = await response.json();
            setClientName(data.rows[0].client);
        }
        fetchConfig();
    }, []);

    const [roles, setRoles] = useState<any>([]);
    useEffect(() => {
        async function fetchConfig() {
            const response = await authenticatedGetJson(
                `${backendUrl}/get_roles`,
            );
            setRoles(response);
        }
        fetchConfig();
    }, []);

    const questions = t('questions') as unknown as any[];

    const new_subtitle = client_name.replace(/_/g, ' ');
    return (
        <>
            {client_name && (
                <React.Fragment>
                    <Subtitle>
                        {t('subtitle', { survey_id: new_subtitle })}
                    </Subtitle>
                    <Questions>
                        {questions.map((question: any, index: any) => {
                            const currentAnswer = answersObject[question.key];
                            switch (question.type) {
                                case 'dropdown':
                                    return (
                                        <MoreInfoDropdown
                                            key={`more-info-question-${index}`}
                                            keyString={`more-info-question-${index}`}
                                            title={question.title}
                                            answers={question.answers}
                                            placeholder={question.placeholder}
                                            currentAnswer={currentAnswer}
                                            setCurrentAnswer={(
                                                answer: string,
                                            ) =>
                                                setAnswersObject({
                                                    ...answersObject,
                                                    [question.key]: answer,
                                                })
                                            }
                                        />
                                    );
                                case 'freetext':
                                    return (
                                        <MoreInfoDropdown
                                            key={`more-info-question-${index}`}
                                            keyString={`more-info-question-${index}`}
                                            title={question.title}
                                            defaultValue={
                                                question.defaultValue || ''
                                            }
                                            answers={roles.map(
                                                (obj: any) => obj.role,
                                            )}
                                            placeholder={question.placeholder}
                                            currentAnswer={getCurrentRole(
                                                currentAnswer,
                                                roles,
                                            )}
                                            returnIndex={true}
                                            setCurrentAnswer={(
                                                answer: string,
                                            ) =>
                                                setAnswersObject({
                                                    ...answersObject,
                                                    [question.key]: answer,
                                                })
                                            }
                                        />
                                    );
                            }
                        })}
                    </Questions>
                </React.Fragment>
            )}
        </>
    );
};

const MoreInfoPage: React.FC = (): React.ReactElement => {
    const moreInfoAnswers = useSelector(
        (state: State): MoreInfoAnswerObject =>
            state.surveyState.userInfos.moreInfo,
    );

    const [answers, setAnswers] = useState(
        moreInfoAnswers as any as MoreInfoAnswerObject,
    );

    const dispatch = useDispatch();
    const { survey_id, uid } = useParams();

    const submitAnswers = () => {
        dispatch(submitMoreInfoAnswers(answers, survey_id, uid, performNext));
    };

    const { t } = useTranslation(['Informations']);

    let emailAuth: any;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;
    console.log(stateVar);
    if (!stateVar || !stateVar.emailAuth) {
        navigate(`/${survey_id}/${uid}`, { replace: true });
    } else {
        emailAuth = stateVar.emailAuth;
    }

    const performNext = (success: boolean) => {
        if (success) {
            navigate(`/${survey_id}/${uid}/${t('nextViewSlug')}`, {
                state: {
                    levels_state: null,
                    emailAuth: emailAuth,
                },
            });
        }
    };

    return (
        <InformationPageTemplate
            title={t('title') || ''}
            tagline={t('tagline') || ''}
            content={getMoreInfoPageContent(answers, setAnswers)}
            nextText={t('nextText') || ''}
            previousText={t('previousText') || ''}
            previousPath={`/${survey_id}/${uid}/${t('previousViewSlug')}`}
            middleImage={t('middleImage') || ''}
            canOverflow={true}
            shouldNavForward={
                Object.values(moreInfoAnswers).filter((value) => !!value)
                    .length === t('questions').length
            }
            buttonDisabled={
                Object.values(answers).filter((value) => !!value).length !==
                t('questions').length
            }
            onButtonClick={submitAnswers}
            logoImage="/talentbuilderlogo.png"
            emailAuth={emailAuth}
        />
    );
};

export default MoreInfoPage;
