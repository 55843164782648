import { debugResetApp } from '../actions/surveyActions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const DebugResetPage: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { survey_id, uid } = useParams();

    dispatch(debugResetApp(survey_id, uid));
    return <div>The application progress has been reset !</div>;
};

export default DebugResetPage;
