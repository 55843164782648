import { State } from '../redux-state';
import { useSelector } from 'react-redux';

function useLoadingAndError() {
    const loading = useSelector(
        (state: State): boolean => state.surveyState.loading,
    );
    const error: string | undefined = useSelector(
        (state: State): string => state.surveyState.error || '',
    );
    return {
        loading,
        error,
    };
}

export default useLoadingAndError;
