import HelpPopin from '../components/HelpPopin';
import React from 'react';

const HelpPage: React.FC = (): React.ReactElement => (
    <HelpPopin
        permanentPage={true}
        onClose={() => {
            return;
        }}
    />
);

export default HelpPage;
