import summary from '../assets/svg/sum-edit.svg';
import information from '../assets/svg/sum-info.svg';
import rgpd from '../assets/svg/sum-rgpd.svg';
import { breakpoints, colors, fonts } from '../styles';
import CircleIcon from './CircleIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface EditMenu {
    className?: string;
    overviewPath: string;
    informationsPath: string;
    openRGPD: () => void;
    tutorialPath: string;
    levels_state: any;
    emailAuth: any;
}

const EditContentWrapper = styled.div<{ show: boolean }>`
    display: ${(props) => (props.show ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${colors.white};
    border-radius: 5px;
    box-sizing: border-box;
    padding: 30px;
    z-index: 4;
    outline: none;
    font-size: 16px;
    ${breakpoints.sm} {
        top: 18px;
    }
    ${breakpoints.md} {
        top: 18px;
    }
    ${breakpoints.lg} {
        top: 18px;
    }
    ${breakpoints.largeDesktop} {
        top: 18px;
    }
    ${breakpoints.largerDesktop} {
        top: 18px;
    }
`;

const EditMenuWrapper = styled.div`
    position: relative;
`;

const EditItemStyle = `
padding-left: 0px;
background-image: url('${(props: { icon: any }) => props.icon}');
background-repeat: no-repeat;
background-position-y: center;
background-position-x: 5px;

cursor: pointer;`;

const EditItem = styled.div<{ icon?: string }>`
    ${EditItemStyle}
`;

const EditText = styled.div`
    white-space: nowrap;
`;

const EditTextTitle = styled.div`
    color: ${colors.black};
    text-transform: uppercase;
    font-weight: bold;
`;

const EditTextContent = styled.div`
    color: ${colors.black};
`;

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    display: block;
    margin-bottom: 23px;
    &:last-child {
        margin-bottom: 0;
    }
    ${breakpoints.sm} {
        width: 240px;
    }
    ${breakpoints.md} {
        width: 240px;
    }
    ${breakpoints.lg} {
        width: 240px;
    }
    ${breakpoints.largeDesktop} {
        width: 240px;
    }
    ${breakpoints.largerDesktop} {
        width: 240px;
    }
`;

const StyledEditItem = styled.div<{ icon?: string }>`
    ${EditItemStyle}
    margin-bottom: 23px;
`;

const PositionedCircleIcon = styled(CircleIcon)`
    position: relative;
    z-index: 20;
    margin-top: 18px;
`;

const EditMenu: React.FC<EditMenu> = ({
    className,
    openRGPD,
    informationsPath,
    overviewPath,
    tutorialPath,
    levels_state,
    emailAuth,
}) => {
    const { t } = useTranslation(['SurveyWelcome']);
    const [showMenu, setShowMenu] = useState(false);

    return (
        <EditMenuWrapper className={className}>
            <PositionedCircleIcon
                type={showMenu ? 'close' : 'edit'}
                active={true}
                backgroundColor={showMenu ? colors.white : colors.white}
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            />
            <EditContentWrapper show={showMenu} tabIndex={-1}>
                {/* <StyledEditItem onClick={() => openRGPD()} icon={rgpd}>
                    <EditText>
                        <EditTextTitle>{t('rgpdTitle')}</EditTextTitle>
                        <EditTextContent>{t('rgpdContent')}</EditTextContent>
                    </EditText>
                </StyledEditItem> */}
                <StyledNavLink
                    to={informationsPath}
                    state={{ emailAuth: emailAuth }}
                >
                    <EditItem icon={information}>
                        <EditText>
                            <EditTextTitle>
                                {t('informationTitle')}
                            </EditTextTitle>
                            <EditTextContent>
                                {t('informationContent')}
                            </EditTextContent>
                        </EditText>
                    </EditItem>
                </StyledNavLink>
                <StyledNavLink
                    to={tutorialPath}
                    state={{ levels_state: levels_state, emailAuth: emailAuth }}
                >
                    <EditItem icon={rgpd}>
                        <EditText>
                            <EditTextTitle>{t('tutorialTitle')}</EditTextTitle>
                            <EditTextContent>
                                {t('tutorialContent')}
                            </EditTextContent>
                        </EditText>
                    </EditItem>
                </StyledNavLink>
                <StyledNavLink
                    to={overviewPath}
                    state={{ levels_state: levels_state, emailAuth: emailAuth }}
                >
                    <EditItem icon={summary}>
                        <EditText>
                            <EditTextTitle>{t('summaryTitle')}</EditTextTitle>
                            <EditTextContent>
                                {t('summaryContent')}
                            </EditTextContent>
                        </EditText>
                    </EditItem>
                </StyledNavLink>
            </EditContentWrapper>
        </EditMenuWrapper>
    );
};

export default EditMenu;
