import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

interface FadeProps {
    state: TransitionStatus;
    duration: number;
}

export const Fade = styled.div<FadeProps>`
    transition: ${({ duration }) => duration}s;
    opacity: 1;
    opacity: ${({ state }) => {
        return state === 'entering' || state === 'entered' ? 1 : 0;
    }};

    /* change color
    background: ${({ state }) => {
        switch (state) {
            case 'entering':
                return 'red';
            case 'entered':
                return 'blue';
            case 'exiting':
                return 'green';
            case 'exited':
                return 'yellow';
        }
    }}; */
`;
