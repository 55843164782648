import { useEffect, useRef } from 'react';

/**
 * Custom hook to add event listener to a Functionnal Component
 * @param eventName
 * @param handler
 * @param element
 */

function useEventListener(
    eventName: string,
    handler: (e: KeyboardEvent) => void,
    element = window,
) {
    // Create a ref that stores handler
    const savedHandler = useRef<(e: KeyboardEvent) => void>(() => {
        return;
    });

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        // Make sure element supports addEventListener
        // On
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        const eventListener: EventListener = (event) =>
            savedHandler.current(<any>event);

        // Add event listener
        element.addEventListener(eventName, eventListener);

        // Remove event listener on cleanup
        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]); // Re-run if eventName or element changes
}

export default useEventListener;
