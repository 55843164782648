import arrow from '../assets/svg/dropdownarrow.svg';
import thumbDownSelected from '../assets/svg/thumb-down-selected.svg';
import thumbDown from '../assets/svg/thumb-down.svg';
import thumbUpSelected from '../assets/svg/thumb-up-selected.svg';
import thumbUp from '../assets/svg/thumb-up.svg';
import breakpoints from '../styles/breakpoints';
import colors from '../styles/colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export enum QuestionType {
    Choice = 'choice',
    FreeText = 'freetext',
    Dropdown = 'dropdown',
}

interface Answer {
    feedbackOptionId: number;
    feedbackQuestionId: number;
    feedback?: string;
}

interface Option {
    id: number;
    name: string;
}

interface QuestionProps {
    name: string;
    type: string;
    currentAnswer: Answer;
    setCurrentAnswer?: (arg0: any) => void;
    options: Array<Option>;
    counter: number;
}

const QuestionContainer = styled.div`
    margin-bottom: 32px;
`;

const QuestionTitle = styled.div``;

const QuestionIndex = styled.div`
    margin-right: 6px;
`;

const QuestionHeader = styled.div`
    font-size: 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    ${breakpoints.onlyMobile} {
        font-size: 16px;
    }
`;

const AnswerButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
`;

const DropdownChoices = styled.select`
    appearance: none;
    border: 1px solid ${colors.white20};
    border-radius: 8px;
    background: white;
    color: ${colors.textColor1};
    width: 100%;
    height: 50px;
    padding: 15px;
    font-size: 13.5px;
    box-sizing: border-box;
    background-image: url('${arrow}');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    text-overflow: ellipsis;
    ${breakpoints.onlyMobile} {
        font-size: 13px;
    }
    ${breakpoints.sm} {
        width: 50%;
    }
    ${breakpoints.largeDesktop} {
        width: 85%;
    }
`;

const ColoredOption = styled.option`
    ${breakpoints.onlyMobile} {
        font-size: 13px;
        width: 100%;
    }
    color: ${colors.black};
`;

const Option = styled.div<{ optionId: number; backgroundColor: string }>`
    border: solid 1px red;
    margin-right: 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: ${(props) =>
        props.optionId === 1 ? 'solid 1px #41953F' : 'solid 1px #D93839'};
    background-color: ${(props) => props.backgroundColor};
`;

const OptionImage = styled.img`
    width: 21px;
    height: 21px;
    padding: 15px 40px;
    ${breakpoints.onlyMobile} {
        padding: 10px 24px;
    }
`;

const TextArea = styled.textarea`
    resize: none;
    width: 75%;
    height: 65px;
    margin-left: 30px;
    border-radius: 4px;
    border-color: #808080;
    background: #fff;
    padding: 6px;
    ${breakpoints.onlyMobile} {
        margin-left: 20px;
        width: 80%;
    }

    &.free-text {
        margin-left: 0;
        width: 100%;
        ${breakpoints.onlyMobile} {
            width: 94%;
        }
    }
`;

const AnswerOption: React.FC<any> = ({
    options,
    onOptionSelect,
    currentAnswer,
}) => {
    const [answerOptions, setAnswerOptions] = useState<Array<any>>([]);

    useEffect(() => {
        setAnswerOptions(
            options.map((option: any) => {
                return {
                    id: option.id,
                    type: option.name,
                    isSelected: currentAnswer
                        ? currentAnswer?.feedbackOptionId === option.id
                        : false,
                    icon:
                        currentAnswer &&
                        currentAnswer?.feedbackOptionId === option.id
                            ? option.id === 1
                                ? thumbUpSelected
                                : thumbDownSelected
                            : option.id === 1
                            ? thumbUp
                            : thumbDown,
                    selectedBackgroundColor:
                        currentAnswer &&
                        currentAnswer?.feedbackOptionId === option.id
                            ? option.id === 1
                                ? '#41953F'
                                : '#D93839'
                            : 'transparent',
                };
            }),
        );
    }, []);

    const handleOptionSelect = (selectedOptionId: number) => {
        answerOptions.forEach((item) => {
            if (item.id === selectedOptionId) {
                item.icon = item.id === 1 ? thumbUpSelected : thumbDownSelected;
                item.isSelected = true;
                item.selectedBackgroundColor =
                    item.id === 1 ? '#41953F' : '#D93839';
            } else {
                item.icon = item.id === 1 ? thumbUp : thumbDown;
                item.isSelected = false;
                item.selectedBackgroundColor = 'transparent';
            }
        });
        setAnswerOptions([...answerOptions]);
        onOptionSelect(selectedOptionId);
    };

    return (
        <React.Fragment>
            {answerOptions.map((option, index) => (
                <Option
                    optionId={option.id}
                    key={index}
                    backgroundColor={option.selectedBackgroundColor}
                >
                    <OptionImage
                        src={option.icon}
                        onClick={() => handleOptionSelect(option.id)}
                    ></OptionImage>
                </Option>
            ))}
        </React.Fragment>
    );
};

const Question: React.FC<QuestionProps> = ({
    name,
    counter,
    currentAnswer,
    type,
    options,
    setCurrentAnswer,
}) => {
    const [selectedOptionId, setSelectedOptionId] = useState<any>();
    const [feedback, setFeedback] = useState<any>('');
    const handleOptionSelect = (selectedOptionId: number) => {
        setSelectedOptionId(selectedOptionId);
        if (setCurrentAnswer) {
            setCurrentAnswer({
                id: selectedOptionId,
                feedback: '',
            });
        }
        if (selectedOptionId === 2) {
            setFeedback('');
        }
    };

    useEffect(() => {
        setSelectedOptionId(currentAnswer?.feedbackOptionId);
        setFeedback(currentAnswer?.feedback);
    }, []);

    return (
        <QuestionContainer>
            <QuestionHeader>
                <QuestionIndex>{counter}.</QuestionIndex>
                <QuestionTitle>{name}</QuestionTitle>
            </QuestionHeader>
            <AnswerButtonContainer>
                {type === QuestionType.Choice && (
                    <AnswerOption
                        options={options}
                        onOptionSelect={(selectedOptionId: number) =>
                            handleOptionSelect(selectedOptionId)
                        }
                        currentAnswer={currentAnswer}
                    />
                )}
                {type === QuestionType.FreeText && (
                    <TextArea
                        className="free-text"
                        placeholder="Write here "
                        value={feedback || ''}
                        maxLength={500}
                        onChange={(e) => {
                            if (setCurrentAnswer) {
                                setCurrentAnswer({
                                    id: selectedOptionId,
                                    feedback: e.target.value,
                                });
                            }
                            setFeedback(e.target.value);
                        }}
                    />
                )}
                {type === QuestionType.Dropdown && (
                    <DropdownChoices
                        onChange={(e) => {
                            if (setCurrentAnswer) {
                                setCurrentAnswer({
                                    id: e.target.options[e.target.selectedIndex]
                                        .dataset.id,
                                    feedback: e.target.value,
                                });
                            }
                            setFeedback(e.target.value);
                        }}
                        value={currentAnswer?.feedback || undefined}
                        defaultValue={
                            (!currentAnswer?.feedback && 'Please Select') ||
                            undefined
                        }
                    >
                        <ColoredOption disabled={true}>
                            {'Please Select'}
                        </ColoredOption>
                        {options.map((option) => (
                            <ColoredOption
                                key={option.name}
                                data-id={option.id}
                            >
                                {option.name}
                            </ColoredOption>
                        ))}
                    </DropdownChoices>
                )}
            </AnswerButtonContainer>
            {/* {selectedOptionId === 2 && (
                <React.Fragment>
                    <Label>Any more Feedback you want to add </Label>
                    <TextArea
                        placeholder="Write here"
                        value={feedback || ''}
                        maxLength={500}
                        onChange={(e) => {
                            if (setCurrentAnswer) {
                                setCurrentAnswer({
                                    id: selectedOptionId,
                                    feedback: e.target.value,
                                });
                            }
                            setFeedback(e.target.value);
                        }}
                    />
                </React.Fragment>
            )} */}
        </QuestionContainer>
    );
};

export default Question;
