import DomainAndImageMapping from '../assets/page-text/DomainAndImageMapping.json';
import { Domain, Subdomain } from '../model/models';
import { breakpoints } from '../styles';
import ClickableDomain from './ClickableDomain';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const ClickableDomainsListWrapper = styled.div`
    max-height: 475px;
    ${breakpoints.onlyMobile} {
        max-height: 300px;
    }
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    > * {
        margin-bottom: 5px;
    }
`;

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`;

interface ClickableDomainsListProps {
    survey_id: any;
    uid: any;
    domains: Domain[];
    showScore?: boolean;
    levels_state?: any;
    emailAuth?: any;
}

const getDomainAverageLevel = (domain: Domain): number => {
    return (
        Math.round(
            (domain.subdomains.reduce(
                (acc: any, subdomains: Subdomain) =>
                    acc + (subdomains.level || 1),
                0,
            ) /
                domain.subdomains.length) *
                10,
        ) / 10
    );
};

const ClickableDomainsList: React.FC<ClickableDomainsListProps> = ({
    survey_id,
    uid,
    domains,
    levels_state,
    showScore = false,
    emailAuth,
}) => (
    <ClickableDomainsListWrapper>
        {domains.map((domain: Domain) => (
            <StyledNavLink
                key={domain.id}
                to={`/${survey_id}/${uid}/survey/${domain.id}`}
                state={{ levels_state: levels_state, emailAuth: emailAuth }}
            >
                <ClickableDomain
                    score={
                        showScore ? getDomainAverageLevel(domain) : undefined
                    }
                    image={DomainAndImageMapping[domain.idx]}
                    title={domain.title}
                    progress={domain.completedSubdomains}
                    total={domain.subdomains.length}
                />
            </StyledNavLink>
        ))}
    </ClickableDomainsListWrapper>
);

export default ClickableDomainsList;
