/**
 * This is a test file to see how re-usage of certain
 * css in CONTAINERS only can make sense in the application.
 */
import { css } from 'styled-components';

const maxWidth = 1024;
const maxHeight = 768;
const nextStepHeight = 235;

export default {
    maxHeight,
    maxWidth,
    nextStepHeight,
    fullPage: css`
        width: 100%;
        box-sizing: border-box;
        padding: 40px 22px 7px 22px;
        display: flex;
        flex-direction: column;
    `,
    fullPageContent: css`
        width: ${maxWidth}px;
        min-height: ${maxHeight}px;
        margin: auto;
    `,
    containNoRepeatBackground: css`
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    `,
    coverNoRepeatBackground: css`
        background-repeat: no-repeat;
        background-size: cover;
    `,
};
