import { submitFeedback } from '../actions/feedbackActions';
import { updateUserCompleted } from '../actions/surveyActions';
import { State } from '../redux-state';
import breakpoints from '../styles/breakpoints';
import Button from './Button';
import Question from './Question';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Option {
    id: number;
    name: string;
}

interface QuestionObj {
    id: number;
    name: string;
    type: string;
    isMandatory: boolean;
    options: Array<Option>;
}

interface Answer {
    feedbackOptionId: number;
    feedbackQuestionId: number;
    feedback?: any;
}
interface FeedbackQuestionProps {
    questions: Array<QuestionObj>;
    answers: Array<Answer>;
}

interface NextStepContainerProps {
    leftSize: number;
}

const NextStepContainer = styled.div<NextStepContainerProps>`
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 1em 0 1em 0;
    justify-content: flex-end;
    ${breakpoints.onlyMobile} {
        justify-content: center;
    }
`;

const FeedbackQuestion: React.FC<FeedbackQuestionProps> = ({
    questions,
    answers,
}) => {
    const { survey_id, uid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let emailAuth: any, levels_state: any;
    const location = useLocation();
    const stateVar = location.state;
    const { t } = useTranslation(['FeedbackQuestion']);
    const leftSize = 55;
    if (stateVar) {
        if ('levels_state' in stateVar) {
            levels_state = stateVar.levels_state;
        }
        if ('emailAuth' in stateVar) {
            emailAuth = stateVar.emailAuth;
        }
    }
    const [feedbackAnswers, setFeedbackAnswers] = useState<Array<any>>([]);
    const answersState = useSelector(
        (state: State): any => state.feedbackState?.answers,
    );
    const [buttonDisabledState, setButtonDisabledState] =
        useState<boolean>(true);
    useEffect(() => {
        setFeedbackAnswers([
            ...questions.map((question: any, index: number) => {
                return {
                    feedbackOptionId:
                        answersState[index]?.feedbackOptionId || null,
                    feedbackQuestionId:
                        answersState[index]?.feedbackQuestionId || question.id,
                    feedback: answersState[index]?.feedback || '',
                    type: question.type,
                    isMandatory: question.isMandatory,
                };
            }),
        ]);
    }, []);

    useEffect(() => {
        setButtonDisabledState(getButtonDisabledState());
    }, [feedbackAnswers]);

    const setAnswersObject = (answer: any, questionId: number) => {
        const feedbackAnswer = feedbackAnswers.find(
            (_) => _.feedbackQuestionId === questionId,
        );
        feedbackAnswer.feedbackOptionId = answer.id;
        feedbackAnswer.feedback = answer.feedback;
        setFeedbackAnswers([...feedbackAnswers]);
    };

    const getButtonDisabledState = () => {
        return feedbackAnswers.some((_) => _.isMandatory && !_.feedback);
    };
    const onButtonClick = () => {
        dispatch(submitFeedback(feedbackAnswers, uid));
        dispatch(updateUserCompleted(survey_id, uid));
        navigate(`/${survey_id}/${uid}/whatsnext`, {
            state: {
                levels_state: levels_state,
                emailAuth: emailAuth,
            },
        });
    };

    return (
        <React.Fragment>
            {questions.map((question, index) => (
                <Question
                    name={question.name}
                    type={question.type}
                    key={index + 1}
                    counter={index + 1}
                    currentAnswer={answers[index]}
                    options={question.options}
                    setCurrentAnswer={(answer: any) => {
                        setAnswersObject(answer, question.id);
                    }}
                />
            ))}

            <NextStepContainer leftSize={leftSize}>
                {buttonDisabledState ? (
                    <Button disabled={buttonDisabledState}>
                        {t('submitFeedback')}
                    </Button>
                ) : (
                    <Button onClick={onButtonClick}>
                        {t('submitFeedback')}
                    </Button>
                )}
            </NextStepContainer>
        </React.Fragment>
    );
};

export default FeedbackQuestion;
