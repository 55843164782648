import { breakpoints, colors, fonts } from '../styles';
import useEventListener from '../utils/useEventListener';
import useViewport from '../utils/useViewport';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface AnswerButtonProps {
    index?: any;
    level?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    answerLevel?: any;
    shortcut: string;
    color?: string;
    backgroundType: 'content' | 'shortcut';
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    className?: string;
    children?: React.ReactNode;
    tooltipTextColor?: string;
    to?: string;
    levels_state?: any;
    loading?: boolean;
    cursor?: boolean;
    emailAuth?: any;
}

interface AnswerButtonWrapperCSSProps {
    color: string;
    showBorder: boolean;
    padding: boolean;
    shouldShowCursor: boolean;
}

interface ShortcutIndicatorCSSProps {
    color: string;
    showBackground: boolean;
    textColor: string;
    margin: boolean;
}

interface LoadingIndicatorCSSProps {
    show: boolean;
}

const AnswerButtonWrapper = styled.div<AnswerButtonWrapperCSSProps>`
    display: flex;
    justify-content: center;
    ${breakpoints.md} {
        justify-content: space-between;
    }
    ${breakpoints.sm} {
        justify-content: space-between;
    }
    ${breakpoints.lg} {
        justify-content: space-between;
    }
    ${breakpoints.largerDesktop} {
        justify-content: space-between;
    }
    ${breakpoints.largeDesktop} {
        justify-content: space-between;
    }
    box-sizing: border-box;
    border: solid 2px
        ${(props) => (props.showBorder ? '#e6e6e6' : 'transparent')};
    border-radius: 3px;
    padding: ${(props) => (props.padding ? 20 : 0)}px;
    align-items: center;
    background-color: ${(props) => props.color};
    cursor: ${(props) => (props.shouldShowCursor ? 'pointer' : 'inherit')};
    position: relative;
    z-index: 3;
    &:hover {
        border: solid 2px
            ${(props) => (props.showBorder ? '#c4bdbd' : 'transparent')};
    }
`;

const Text = styled.div`
    color: ${colors.libertyBlue};
    ${fonts.textContent};
`;

const ShortcutIndicator = styled.div<ShortcutIndicatorCSSProps>`
    ${fonts.indication};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        (props.showBackground && props.color) || colors.white};
    color: ${(props) => (props.showBackground && colors.white) || props.color};
    font-weight: bold;
    border-radius: 2px;
    color: ${(props) => props.textColor};
    margin-right: ${(props) => (props.margin ? 10 : 0)}px;
`;

const ShortcutText = styled.div`
    padding: 5px 10px;
    box-sizing: border-box;
    text-align: center;
`;

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`;

const LoadingIndicator = styled.div<LoadingIndicatorCSSProps>`
    position: absolute;
    top: 0;
    z-index: 2;
    background: ${(props) => props.color};
    width: 100%;
    height: 100%;
    border-radius: 3px;
    display: ${(props) => (props.show ? 'block' : 'none')};
    @keyframes load {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }
    animation: load 0.5s ease;
`;

const AnswerButtonBackgroundWrapper = styled.div`
    position: relative;
`;

const AnswerButton: React.FC<AnswerButtonProps> = ({
    index,
    level,
    answerLevel,
    shortcut,
    color = 'black',
    backgroundType = 'shortcut',
    onClick,
    className,
    children,
    to,
    levels_state,
    loading,
    cursor = true,
    emailAuth,
}): React.ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const { width } = useViewport();
    const { t } = useTranslation(['HelpPopin']);

    const levelText = {
        1: t('answerLevel1'),
        2: t('answerLevel2'),
        3: t('answerLevel3'),
        4: t('answerLevel4'),
        5: t('answerLevel2'),
        6: t('answerLevel4'),
    };
    const ButtonContent = (
        <AnswerButtonBackgroundWrapper>
            <AnswerButtonWrapper
                className={className}
                shouldShowCursor={cursor}
                color={(backgroundType === 'content' && color) || 'transparent'}
                showBorder={backgroundType === 'shortcut'}
                onClick={(e) => onClick && onClick(e)}
                padding={level != null}
                ref={ref}
            >
                {children ||
                    (index && level != null && (
                        <Text>
                            {levels_state.levels_data[index - 1].answerLevel}
                        </Text>
                    ))}
                {width > breakpoints.value.mobile && (
                    <ShortcutIndicator
                        margin={!level && level != 0}
                        color={color}
                        showBackground={backgroundType === 'shortcut'}
                        textColor={colors.libertyBlue}
                    >
                        <ShortcutText>{shortcut}</ShortcutText>
                    </ShortcutIndicator>
                )}
            </AnswerButtonWrapper>
            <LoadingIndicator show={!!loading} color={color} />
        </AnswerButtonBackgroundWrapper>
    );

    const shortcutCallBack = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === shortcut) ref.current?.click();
        },
        [onClick, shortcut],
    );
    useEventListener('keydown', shortcutCallBack);
    return to ? (
        <StyledNavLink
            to={to}
            state={{ levels_state: levels_state, emailAuth: emailAuth }}
        >
            {ButtonContent}
        </StyledNavLink>
    ) : (
        <React.Fragment>{ButtonContent}</React.Fragment>
    );
};

export default AnswerButton;
