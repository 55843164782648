import defaultMiddleImage from '../assets/people-intro.png';
import { colors, fonts, containerStyles, breakpoints } from '../styles';
import useViewport from '../utils/useViewport';
import Button from './Button';
import Checkbox from './Checkbox';
import Collapsible from './Collapsible';
import InformationLayout from './InformationLayout';
import ProgressControls from './ProgressControls';
import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface IntroductionPageTemplateProps {
    title?: string;
    tagline?: string;
    content: string | React.ReactElement;
    dropdownTitle: string;
    dropdownContent: string | React.ReactElement;
    postDropdownContent: string | React.ReactElement;
    conscentContent: string;
    nextPath?: string;
    previousPath?: string;
    nextText?: string;
    previousText?: string;
    middleImage?: string;
    logoImage?: string;
    breakpoints?: number;
    leftSize?: number;
    leftSideBackgroundColor?: string;
    rightSideBackgroundColor?: string;
    canOverflow?: boolean;
    shouldNavForward?: boolean;
    buttonDisabled?: boolean;
    onButtonClick?: () => void;
    controls?: boolean;
    emailAuth: any;
}

interface ImageSideCSSProps {
    leftSize: number;
}

interface NextStepContainerProps {
    leftSize: number;
}

interface MainContentProps {
    leftSize: number;
    withButton: boolean;
}

const TextContent = styled.div<NextStepContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    ${breakpoints.md} {
        padding-right: ${(props) => (props.leftSize === 100 ? 0 : 60)}px;
    }
    ${breakpoints.lg} {
        padding-right: ${(props) => (props.leftSize === 100 ? 0 : 60)}px;
    }
    > * {
        &:nth-child(1) {
            margin-right: 20px;
        }
    }
`;

const ImageSide = styled.div<ImageSideCSSProps>`
    display: flex;
    align-items: center;
    position: relative;
    width: ${(props) => 100 - props.leftSize}%;
`;

const Title = styled.div`
    ${fonts.title};
    color: ${colors.libertyBlue};
    text-transform: uppercase;
    margin-bottom: 32px;
`;

const Tagline = styled.div`
    ${fonts.subtitle};
    color: ${colors.libertyBlue};
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
`;

const Content = styled.div`
    color: ${colors.libertyBlue};
    margin-top: 32px;
    ${fonts.content};
    line-height: 1.75;
`;

const MiddleImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
`;

const NextStepContainer = styled.div<NextStepContainerProps>`
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    text-decoration: none;
    margin: 1em 0 1em 0;
`;

const MainContent = styled.div<MainContentProps>`
    display: flex;
    > * {
        &:nth-child(1) {
            ${breakpoints.onlyMobile} {
                width: 100%;
                padding-right: 0;
            }
            width: ${(props) => props.leftSize}%;
            padding-right: 60px;
        }
        &:nth-child(2) {
            height: ${(props) =>
                props.withButton
                    ? containerStyles.maxHeight - containerStyles.nextStepHeight
                    : containerStyles.maxHeight}px;
        }
    }
`;

const PositionnedProgressControls = styled(ProgressControls)`
    position: absolute;
    top: 12px;
    right: 0;
    width: 90px;
`;

const LogoImage = styled.img`
    max-height: 100%;
    width: 14.3%;
    margin-right: 10%;

    ${breakpoints.onlyMobile} {
        max-width: 25%;
        max-height: 100%;
        width: 100%;
    }

    ${breakpoints.lg} {
        max-height: 100%;
        width: 14.3%;
        margin-right: 10%;
    }

    ${breakpoints.md} {
        max-height: 100%;
        width: 14.3%;
        margin-right: 10%;
    }
`;

const LogoImageContainer = styled.div`
     {
        display: flex;
        justify-content: flex-end;
    }
`;

const IntroductionPageTemplate: React.FC<IntroductionPageTemplateProps> = ({
    title,
    tagline,
    content,
    dropdownTitle,
    dropdownContent,
    postDropdownContent,
    conscentContent,
    previousPath,
    nextPath,
    nextText,
    logoImage,
    previousText,
    middleImage = defaultMiddleImage,
    leftSize = 55,
    leftSideBackgroundColor = colors.backgroundColor1,
    rightSideBackgroundColor = colors.backgroundColor2,
    shouldNavForward = true,
    canOverflow = false,
    controls = true,
    onButtonClick,
    emailAuth,
}): React.ReactElement => {
    const { width } = useViewport();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    return (
        <InformationLayout
            leftSize={leftSize}
            leftSideBackgroundColor={leftSideBackgroundColor}
            rightSideBackgroundColor={rightSideBackgroundColor}
            withButton={!!nextText}
            canOverflow={canOverflow}
            logoImage={logoImage}
        >
            {/* <LogoImageContainer>
                <LogoImage src={logoImage} />
            </LogoImageContainer> */}
            <MainContent withButton={!!nextPath} leftSize={leftSize}>
                <TextContent leftSize={leftSize}>
                    {title && <Title>{title}</Title>}
                    {tagline && <Tagline>{tagline}</Tagline>}
                    {(typeof content === 'string' && (
                        <Content
                            dangerouslySetInnerHTML={{
                                __html: content as string,
                            }}
                        />
                    )) || <Content>{content}</Content>}

                    <Collapsible
                        title={dropdownTitle}
                        content={dropdownContent}
                    />

                    <Checkbox
                        label={conscentContent}
                        onClick={setButtonDisabled}
                    />

                    {(typeof postDropdownContent === 'string' && (
                        <Content
                            dangerouslySetInnerHTML={{
                                __html: postDropdownContent as string,
                            }}
                        />
                    )) || <Content>{postDropdownContent}</Content>}
                </TextContent>
                {width > breakpoints.value.mobile && (
                    <ImageSide leftSize={leftSize}>
                        {controls && (
                            <PositionnedProgressControls
                                emailAuth={emailAuth}
                                backPath={previousPath}
                                forwardPath={
                                    (!buttonDisabled &&
                                        shouldNavForward &&
                                        nextPath) ||
                                    undefined
                                }
                            />
                        )}
                        <MiddleImage src={middleImage} />
                    </ImageSide>
                )}
            </MainContent>
            {(onButtonClick && !nextPath && (
                <NextStepContainer leftSize={leftSize}>
                    {buttonDisabled ? (
                        <Button disabled={buttonDisabled}>{nextText}</Button>
                    ) : (
                        <Button
                            onClick={onButtonClick}
                            disabled={buttonDisabled}
                        >
                            {nextText}
                        </Button>
                    )}
                </NextStepContainer>
            )) ||
                (nextPath && (
                    <NextStepContainer leftSize={leftSize}>
                        <Fragment>
                            {width < breakpoints.value.desktop &&
                                previousPath && (
                                    <NavLink to={previousPath}>
                                        <Button
                                            onClick={onButtonClick}
                                            buttonType={`secondary`}
                                        >
                                            {previousText}
                                        </Button>
                                    </NavLink>
                                )}
                        </Fragment>
                        {buttonDisabled ? (
                            <Button disabled={buttonDisabled}>
                                {nextText}
                            </Button>
                        ) : (
                            <NavLink
                                to={nextPath}
                                state={{ emailAuth: emailAuth }}
                            >
                                <Button
                                    onClick={onButtonClick}
                                    disabled={buttonDisabled}
                                >
                                    {nextText}
                                </Button>
                            </NavLink>
                        )}
                    </NextStepContainer>
                ))}
        </InformationLayout>
    );
};

export default IntroductionPageTemplate;
