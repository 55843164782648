import { colors } from '../styles';
import React from 'react';
import styled from 'styled-components';

interface DomainBackgroundProps {
    color?: string;
    className?: string;
    children?: React.ReactNode;
}

const DomainBackgroundContent = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const DomainBackgroundWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const DomainBackgroundChildren = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
`;

const DomainBackground: React.FC<DomainBackgroundProps> = ({
    color = colors.white,
    children,
    className,
}) => (
    <DomainBackgroundWrapper className={className}>
        <DomainBackgroundChildren>{children}</DomainBackgroundChildren>
        <DomainBackgroundContent
            xmlns="http://www.w3.org/2000/svg"
            width="280"
            height="281"
            viewBox="0 0 280 281"
        >
            <defs>
                <ellipse id="a" cx="140" cy="140.649" rx="140" ry="140.235" />
                <filter
                    id="b"
                    width="100%"
                    height="100%"
                    x="0%"
                    y="0%"
                    filterUnits="objectBoundingBox"
                >
                    <feGaussianBlur in="SourceGraphic" />
                </filter>
                <filter
                    id="d"
                    width="74.7%"
                    height="75.4%"
                    x="12.6%"
                    y="12.3%"
                    filterUnits="objectBoundingBox"
                >
                    <feGaussianBlur in="SourceGraphic" />
                </filter>
                <filter
                    id="e"
                    width="100%"
                    height="100%"
                    x="0%"
                    y="0%"
                    filterUnits="objectBoundingBox"
                >
                    <feGaussianBlur in="SourceGraphic" />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(0 .012)">
                <mask id="c" fill="#fff">
                    <use href="#a" />
                </mask>
                <use fill={color} fillOpacity=".85" href="#a" />
                <path
                    fill="#000"
                    fillOpacity=".05"
                    d="M70 196.33a156.29 156.29 0 0 1 140 0c19.33 9.68 27.165 33.225 17.5 52.588a39.164 39.164 0 0 1-17.5 17.53 156.29 156.29 0 0 1-140 0c-19.33-9.682-27.165-33.227-17.5-52.59A39.164 39.164 0 0 1 70 196.33z"
                    filter="url(#b)"
                    mask="url(#c)"
                />
                <path
                    fill="#000"
                    fillOpacity=".07"
                    d="M122.5 219.118a39.079 39.079 0 0 1 35 0c4.84 2.424 6.8 8.313 4.375 13.154a9.803 9.803 0 0 1-4.375 4.375 39.079 39.079 0 0 1-35 0c-4.84-2.424-6.8-8.314-4.375-13.154a9.803 9.803 0 0 1 4.375-4.375z"
                    filter="url(#d)"
                    mask="url(#c)"
                />
                <path
                    fill="#FFF"
                    fillOpacity=".2"
                    d="M108.558 7.87c20.196-10.494 43.968-10.494 64.164 0 8.86 4.604 12.45 15.8 8.02 25.006-1.735 3.607-4.55 6.532-8.02 8.336-20.196 10.494-43.968 10.494-64.164 0-8.86-4.604-12.45-15.8-8.02-25.006 1.734-3.607 4.549-6.532 8.02-8.335z"
                    filter="url(#e)"
                    mask="url(#c)"
                />
            </g>
        </DomainBackgroundContent>
    </DomainBackgroundWrapper>
);

export default DomainBackground;

// <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="280" height="281" viewBox="0 0 280 281">
//     <defs>
//         <ellipse id="a" cx="140" cy="140.649" rx="140" ry="140.235"/>
//         <filter id="b" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
//             <feGaussianBlur in="SourceGraphic"/>
//         </filter>
//         <filter id="d" width="74.7%" height="75.4%" x="12.6%" y="12.3%" filterUnits="objectBoundingBox">
//             <feGaussianBlur in="SourceGraphic"/>
//         </filter>
//         <filter id="e" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
//             <feGaussianBlur in="SourceGraphic"/>
//         </filter>
//     </defs>
//     <g fill="none" fillRule="evenodd" transform="translate(0 .012)">
//         <mask id="c" fill="#fff">
//             <use xlink:href="#a"/>
//         </mask>
//         <use fill="#FFF" fillOpacity=".85" xlink:href="#a"/>
//         <path fill="#000" fillOpacity=".05" d="M70 196.33a156.29 156.29 0 0 1 140 0c19.33 9.68 27.165 33.225 17.5 52.588a39.164 39.164 0 0 1-17.5 17.53 156.29 156.29 0 0 1-140 0c-19.33-9.682-27.165-33.227-17.5-52.59A39.164 39.164 0 0 1 70 196.33z" filter="url(#b)" mask="url(#c)"/>
//         <path fill="#000" fillOpacity=".07" d="M122.5 219.118a39.079 39.079 0 0 1 35 0c4.84 2.424 6.8 8.313 4.375 13.154a9.803 9.803 0 0 1-4.375 4.375 39.079 39.079 0 0 1-35 0c-4.84-2.424-6.8-8.314-4.375-13.154a9.803 9.803 0 0 1 4.375-4.375z" filter="url(#d)" mask="url(#c)"/>
//         <path fill="#FFF" fillOpacity=".2" d="M108.558 7.87c20.196-10.494 43.968-10.494 64.164 0 8.86 4.604 12.45 15.8 8.02 25.006-1.735 3.607-4.55 6.532-8.02 8.336-20.196 10.494-43.968 10.494-64.164 0-8.86-4.604-12.45-15.8-8.02-25.006 1.734-3.607 4.549-6.532 8.02-8.335z" filter="url(#e)" mask="url(#c)"/>
//     </g>
// </svg> -->
