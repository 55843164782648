const breakpoints = {
    mobile: 576,
    tablet: 768,
    desktop: 992,
    largeDesktops: 1200,
    largerDesktops: 1400,
};

export default {
    sm: `@media (min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.tablet}px)`,
    md: `@media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop}px)`,
    lg: `@media (min-width: ${breakpoints.desktop}px) and (max-width: ${breakpoints.largeDesktops}px)`,
    largeDesktop: `@media (min-width: ${breakpoints.largeDesktops}px) and (max-width:${breakpoints.largerDesktops}px)`,
    largerDesktop: '@media (min-width:' + breakpoints.largerDesktops + 'px)',
    onlyMobile: '@media (max-width:' + breakpoints.mobile + 'px)',
    value: breakpoints,
};
